import SEO from "../components/seo"
import React, { useEffect, useState } from "react"
import logoBrahma from "../images/promos/brahma-logo.svg"
import logoQuilmes from "../images/logos/newLogoMenu.svg"
import promosBannerDesktop from "../images/banners/promos-desktop.png"
import promosBannerMobile from "../images/banners/promos-mobile.png"
import { IsMobileDevice } from "../../utils/ismobile"
import { Checkbox, Input, Select, Spin } from "antd"
import { Button } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import provincesAndCities from "../../utils/provincesAndCities"
import pdvCodes from "../../utils/pdv-codes"

const PromosPage = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [beesCode, setBeesCode] = useState(null)
  const [address, setAddress] = useState("")
  const [brahmaChecked, setBrahmaChecked] = useState(false)
  const [quilmesChecked, setQuilmesChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [province, setProvince] = useState(null)
  const [city, setCity] = useState(null)
  const provinces = Object.keys(provincesAndCities)
  const [step, setStep] = useState(0)
  const validCodes = pdvCodes.map(pdv => String(pdv.code))

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  useEffect(() => {
    const steps = [
      "Código Bees",
      "Domicilio",
      "Provincia",
      "Localidad",
      "Adhesión Brahma",
      "Adhesión Quilmes",
    ]

    if (step > 0) {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: `Paso ${step}`,
        event_label: steps[step - 1],
        interaction: "True",
        component_name: "boton_adherite",
      })
    }
  }, [step])

  const provincesOptions = provinces.map(province => ({
    value: province,
    label: province,
  }))

  const getCitiesOptions = () =>
    provincesAndCities[province]?.map(city => ({
      value: city,
      label: city,
    }))

  const showSuccess = () =>
    toast.success("Te registraste correctamente en la promo!", {
      position: "top-center",
    })
  const showError = error =>
    toast.error(error, {
      position: "top-center",
    })

  const submit = async () => {
    if (loading) return
    let error

    // if (!address) {
    //   error = "Por favor ingrese un domicilio"
    // }
    //
    // if (!province) {
    //   error = "Por favor seleccione una provincia"
    // }
    //
    // if (!city) {
    //   error = "Por favor seleccione una localidad"
    // }

    if (!brahmaChecked && !quilmesChecked) {
      error = "Debe aceptar al menos una promo"
    }

    // if(beesCode && !validCodes.includes(beesCode)){
    //   error = "Código Bees inválido"
    // }
    const apiUrlCheck = "https://wacopaamericamotmback-prd.azurewebsites.net/api/bees-codes/exists"

    const responseCheck = await fetch(apiUrlCheck, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: beesCode,
      }),
    })

    const dataCheck = await responseCheck.json()
    console.log(dataCheck)
    if (!dataCheck.exists) {
      error = "Código Bees inválido"
    }

    if (!beesCode) {
      error = "Por favor ingrese un código Bees"
    }

    if (error) {
      showError(error)
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: "Error",
        event_label: error,
        interaction: "True",
        component_name: "boton_enviar",
      })
      return
    }

    setLoading(true)

    const apiUrl =
      "https://wacopaamericamotmback-prd.azurewebsites.net/api/promos"

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bees_code: beesCode,
        address: "No completado",
        brahma_checked: brahmaChecked,
        quilmes_checked: quilmesChecked,
        province: "No completado",
        city: "No completado",
      }),
    })

    const data = await response.json()

    if (data.success) {
      setAddress("")
      setBeesCode("")
      setBrahmaChecked(false)
      setQuilmesChecked(false)
      setProvince(null)
      setCity(null)
      showSuccess("Te registraste correctamente en la promo!")
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: "Success",
        event_label: "",
        interaction: "True",
        component_name: "boton_enviar",
        userUid: data.result.insertId,
      })
    } else {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Form",
        event_action: "Error",
        event_label: data.message,
        interaction: "True",
        component_name: "boton_enviar",
      })
      showError(data.message)
    }

    setLoading(false)
  }

  return (
    <>
      <SEO title={"Promos"} description={""} />
      <ToastContainer />
      <div style={{
        display: "flex",
      }}>
        <section
          style={{
            display: "flex",
            alignItems: "center",
            height: "auto",
            minHeight: "100vh",
            width: isMobile ? "100vw" : "40vw",
            flexDirection: "column",
            fontFamily: "Tungsten",
            background: "#373a3a",
            justifyContent: isMobile ? "center" : "space-evenly",
            gap: "2vh",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "80%",
              maxWidth: "800px",
              justifyContent: "center",
              gap: isMobile ? "10%" : "20%",
            }}
          >
            <div
              style={{
                width: isMobile ? "40%" : "30%",
                aspectRatio: "1/1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <a
                target="_blank"
                href={"/Promo Quilmes.jpg"}
                style={{
                  width: "100%",
                  minHeight: "90px",
                  height: "100% !important",
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <img
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    height: "100%",
                    minHeight: isMobile ? "100px" : "auto",
                    objectFit: "contain",
                    marginBottom: isMobile ? "15%" : 0,
                    marginTop: 0,
                  }}
                  src={logoQuilmes}
                  alt={"quilmes"}
                />
              </a>
              <a
                target="_blank"
                href={"/Promo Quilmes.jpg"}
                style={{
                  textDecoration: "none",
                  // marginTop: isMobile ? "-15%" : 0,
                  textAlign: "center",
                  color: "white",
                  fontFamily: "Tungsten-Medium",
                  fontSize: isMobile ? "18px" : "1.7rem",
                }}
              >
                <Button
                  style={{
                    fontSize: isMobile ? "18px" : "1.7rem",
                    width: isMobile ? "auto" : "200px",
                  }}
                >
                  Conocé promo envases
                </Button>
              </a>
            </div>
            <div
              style={{
                width: isMobile ? "40%" : "30%",
                aspectRatio: "1/1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <a
                target="_blank"
                href={"/Flyer-Promo-Brahma.jpg"}
                style={{
                  width: "100%",
                  height: "100% !important",
                  minHeight: "90px",
                  display: "flex",
                  flexGrow: 1,
                }}
              >
                <img
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    height: "100%",
                    minHeight: isMobile ? "100px" : "auto",
                    objectFit: "contain",
                    marginBottom: isMobile ? "15%" : 0,
                    marginTop: 0,
                  }}
                  src={logoBrahma}
                  alt={"brahma"}
                />
              </a>
              <a
                target="_blank"
                href={"/Flyer-Promo-Brahma.jpg"}
                style={{
                  textDecoration: "none",
                  // marginTop: isMobile ? "-15%" : 0,
                  textAlign: "center",
                  color: "white",
                  fontFamily: "Tungsten-Medium",
                  fontSize: isMobile ? "18px" : "1.7rem",
                }}
              >
                <Button
                  style={{
                    width: isMobile ? "auto" : "200px",
                    fontSize: isMobile ? "18px" : "1.7rem",
                  }}
                >
                  Conocé promo chapitas
                </Button>
              </a>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: isMobile ? "5px" : "2vh",
              marginTop: isMobile ? "5vh" : "0",
            }}
          >
            <h2
              style={{
                color: "white",
                fontSize: isMobile ? "40px" : "3.5rem",
                margin: 0,
                marginTop: isMobile ? "50px" : "0",
              }}
            >
              Promos Quilmes y Brahma
            </h2>
            {/*<ArrowDownOutlined*/}
            {/*  style={{*/}
            {/*    margin: 0,*/}
            {/*    fontSize: isMobile ? "20px" : "1.5rem",*/}
            {/*    cursor: "pointer",*/}
            {/*    animation: "updown 2s infinite",*/}
            {/*  }}*/}
            {/*  onClick={() => {*/}
            {/*    document*/}
            {/*      .getElementById("banner")*/}
            {/*      .scrollIntoView({ behavior: "smooth" })*/}
            {/*  }}*/}
            {/*/>*/}
          </div>
          {isMobile && (
            <img
              id={"banner"}
              src={promosBannerMobile}
              alt={"banner"}
              style={{
                width: "100%",
                objectFit: "contain",
              }}
            />
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                width: isMobile ? "80%" : "450px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Input
                placeholder={"Código Bees"}
                style={{
                  height: "44px",
                  width: "100%",
                  fontSize: "1.7rem",
                  fontFamily: "Tungsten-Medium",
                  background: "white",
                }}
                type={"numeric"}
                onChange={e => {
                  const lastChar = e.target.value.slice(-1)
                  if (e.target.value === "" || !isNaN(parseInt(lastChar))) {
                    setBeesCode(
                      e.target.value.length > 14
                        ? e.target.value.slice(0, 14)
                        : e.target.value
                    )
                  }
                }}
                pattern="[0-9]*"
                value={beesCode}
                onBlur={() => {
                  setStep(1)
                }}
              />
              <p
                style={{
                  color: "white",
                  fontFamily: "Tungsten-Medium",
                  fontSize: isMobile ? "15px" : "1.35rem",
                }}
              >
                Coloca el número de hasta 14 dígitos que figura en tu número de
                cuenta.
              </p>
            </div>
            {/*<div*/}
            {/*  style={{*/}
            {/*    width: isMobile ? "80%" : "450px",*/}
            {/*    display: "flex",*/}
            {/*    flexDirection: "column",*/}
            {/*    marginBottom: "20px",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Input*/}
            {/*    placeholder={"Domicilio"}*/}
            {/*    style={{*/}
            {/*      height: "44px",*/}
            {/*      width: "100%",*/}
            {/*      fontSize: "1.5rem",*/}
            {/*      fontFamily: "Tungsten-Medium",*/}
            {/*      background: "white",*/}
            {/*    }}*/}
            {/*    onChange={e => setAddress(e.target.value)}*/}
            {/*    value={address}*/}
            {/*    onBlur={() => {*/}
            {/*      setStep(2)*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  style={{*/}
            {/*    width: isMobile ? "80%" : "450px",*/}
            {/*    display: "flex",*/}
            {/*    flexDirection: "column",*/}
            {/*    marginBottom: "20px",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Select*/}
            {/*    placeholder={"Provincia"}*/}
            {/*    style={{*/}
            {/*      height: "44px",*/}
            {/*      width: "100%",*/}
            {/*      fontSize: isMobile ? "18px" : "1.5rem",*/}
            {/*      fontFamily: "Tungsten-Medium",*/}
            {/*      background: "white",*/}
            {/*      display: "flex",*/}
            {/*      alignItems: "center",*/}
            {/*      justifyContent: "center",*/}
            {/*    }}*/}
            {/*    onBlur={() => {*/}
            {/*      setStep(3)*/}
            {/*    }}*/}
            {/*    bordered={false}*/}
            {/*    options={provincesOptions}*/}
            {/*    value={province}*/}
            {/*    showSearch={true}*/}
            {/*    onChange={value => {*/}
            {/*      setProvince(value)*/}
            {/*      setCity(null)*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  style={{*/}
            {/*    background: "green",*/}
            {/*    width: isMobile ? "80%" : "450px",*/}
            {/*    display: "flex",*/}
            {/*    flexDirection: "column",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Select*/}
            {/*    placeholder={"Localidad"}*/}
            {/*    style={{*/}
            {/*      height: "44px",*/}
            {/*      width: "100%",*/}
            {/*      fontSize: isMobile ? "18px" : "1.5rem",*/}
            {/*      fontFamily: "Tungsten-Medium",*/}
            {/*      background: "white",*/}
            {/*      display: "flex",*/}
            {/*      alignItems: "center",*/}
            {/*      justifyContent: "center",*/}
            {/*    }}*/}
            {/*    onBlur={() => {*/}
            {/*      setStep(4)*/}
            {/*    }}*/}
            {/*    onChange={value => setCity(value)}*/}
            {/*    value={city}*/}
            {/*    bordered={false}*/}
            {/*    showSearch={true}*/}
            {/*    disabled={!province}*/}
            {/*    options={province ? getCitiesOptions() : []}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Checkbox
                checked={brahmaChecked}
                onChange={e => setBrahmaChecked(e.target.checked)}
                onClick={() => {
                  setStep(5)
                }}
              />
              <p
                style={{
                  color: "white",
                  fontFamily: "Tungsten-Medium",
                  fontSize: isMobile ? "16px" : "1.65rem",
                  margin: 0,
                }}
              >
                Aceptación de adhesión a promo Brahma +{" "}
                <a
                  href={
                    "/Términos y Condiciones Promo Brahma 2024 para PDV v9.pdf"
                  }
                  target={"_blank"}
                >
                  términos y condiciones.
                </a>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Checkbox
                checked={quilmesChecked}
                onChange={e => setQuilmesChecked(e.target.checked)}
                onClick={() => {
                  setStep(6)
                }}
              />
              <p
                style={{
                  color: "white",
                  fontFamily: "Tungsten-Medium",
                  fontSize: isMobile ? "16px" : "1.65rem",
                  margin: 0,
                }}
              >
                Aceptación de adhesión a promo Quilmes +{" "}
                <a
                  href={
                    "/Términos y Condiciones Promoción Pacto Quilmes Blanqueo Envases y Anexo Chapitas NOA.pdf"
                  }
                  target={"_blank"}
                >
                  términos y condiciones.
                </a>
              </p>
            </div>
          </div>
          <Button
            style={{
              width: "200px",
              fontSize: "2rem",
              marginBottom: "5vh",
            }}
            onClick={submit}
          >
            {loading ? <Spin /> : "¡Adherite!"}
          </Button>

        </section>
        {!isMobile && (
          <img
            id={"banner"}
            src={promosBannerMobile}
            alt={"banner"}
            style={{
              width: "60vw",
              objectFit: "contain",
              background: "red"
            }}
          />
        )}
      </div>

    </>
  )
}

export default PromosPage
